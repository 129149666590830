<template>
  <div class="overflow-hidden">
    <div class="bg-light-purple">
      <section
        v-if="isHeroSearchLargeEnabled() || $config.FT_HERO_SEARCH_MOBILE"
        class="relative container items-center hero-section hero-section--search"
        :class="{
          dn: !$config.FT_HERO_SEARCH_MOBILE,
          'dn-l': !isHeroSearchLargeEnabled(),
          'flex-l': isHeroSearchLargeEnabled(),
        }"
      >
        <div class="w-55-l large-search-container">
          <h1 class="hero-headline hero-headline--large">
            {{ $t('home.heading') }}
          </h1>
          <div class="hero-search-placeholder">
            <client-only v-if="isHeroSearchLargeEnabled()">
              <SearchLarge v-if="$screen.large" hero-search-large />
            </client-only>
            <client-only v-if="$config.FT_HERO_SEARCH_MOBILE">
              <div
                v-if="!$screen.large"
                v-observe-visibility="{
                  callback: visibilityChanged,
                  intersection: intersectionOptions,
                }"
              >
                <SearchMobile hero-search-mobile />
              </div>
            </client-only>
          </div>
          <div class="mt3 tc tl-l">
            <nuxt-link
              :to="localePath({ name: 'services' })"
              class="dib link underline link-to-services"
            >
              {{ $t('home.search.allServicesLinkText') }}
            </nuxt-link>
          </div>
        </div>
        <div
          class="relative flex-l items-end-l pt4-l w-45-l overflow-hidden featured-pro-container"
        >
          <img
            :data-srcset="require('~/assets/images/featured-pro@2x.png').srcSet"
            data-src="~assets/images/featured-pro.png"
            alt="Featured Professional"
            class="lazyload max-h-100 featured-pro"
            :class="{ 'featured-pro-xl': $config.FT_HERO_TESTIMONIAL }"
          />
          <div
            v-if="$config.FT_HERO_TESTIMONIAL && testimonial"
            class="absolute hero-testimonial-container"
          >
            <div class="flex flex-row">
              <svg-icon
                v-for="(item, index) in new Array(5)"
                :key="index"
                name="icon-star"
                class="icon-star"
              />
            </div>
            <blockquote class="hero-testimonial">
              <p class="hero-testimonial-text">
                {{ testimonial.text }}
              </p>
              <cite class="db hero-testimonial-author">{{
                testimonial.client
              }}</cite>
            </blockquote>
          </div>
        </div>
        <div class="absolute flex items-center insurance-container">
          <span class="db mr2">
            <svg-icon name="icon-insurance" class="icon-insurance" />
          </span>
          <span class="db">{{ $t('home.insurancePromo') }}</span>
        </div>
      </section>
      <section
        class="featured-height overflow-hidden overflow-visible-l container phx20 items-center-l tc tl-l hero-section hero-section--register"
        :class="
          isHeroSearchLargeEnabled() && $config.FT_HERO_SEARCH_MOBILE
            ? 'dn'
            : $config.FT_HERO_SEARCH_MOBILE
            ? 'dn flex-l'
            : isHeroSearchLargeEnabled()
            ? 'db dn-l'
            : 'flex-l'
        "
      >
        <div class="pa2 pv4-l pl0-l pr4-l w-40-l mb5-l">
          <h1 class="ma0 mb4 mt4-l f1">
            {{ $t('home.heading') }}
          </h1>
          <p class="dn db-l pb4 mb3">{{ $t('home.subheading') }}</p>
          <a
            class="dn-l pointer dib white fw7 br-pill bg-purple button-large"
            @click="$root.$emit('handle-download', 'open')"
          >
            {{ $t('home.getTheApp') }}
          </a>
          <GetAppForm class="dn db-l" />
          <div
            class="flex flex-column flex-row-l items-center pt1 mt3 insurance-container"
          >
            <span class="db mr2-l mb1 mb0-l">
              <svg-icon name="icon-insurance" class="icon-insurance" />
            </span>
            <span>{{ $t('home.insurancePromo') }}</span>
          </div>
        </div>
        <div class="h-100 w-60-l relative overflow-hidden">
          <div class="layer-1 relative" :style="`top: -${scrolled * 0.2}px`">
            <img
              :data-srcset="require('~/assets/images/home-phone@2x.png').srcSet"
              data-src="~assets/images/home-phone.png"
              alt=""
              class="lazyload featured-phone"
              width="500"
              height="681"
            />
          </div>
          <div class="layer-2 relative" :style="`top: -${scrolled * 0.4}px`">
            <img
              data-src="~/assets/icons/ic_bubble_5.svg"
              alt=""
              width="90"
              height="95"
              class="lazyload featured-bubble-5"
            />
            <img
              data-src="~/assets/icons/ic_bubble_6.svg"
              alt=""
              width="84"
              height="93"
              class="featured-bubble-6 lazyload"
            />
          </div>
          <div class="layer-3 relative" :style="`top: -${scrolled * 0.6}px`">
            <img
              data-src="~/assets/icons/ic_bubble_1.svg"
              alt=""
              width="34"
              height="34"
              class="featured-bubble-1 lazyload"
            />
            <img
              data-src="~/assets/icons/ic_bubble_2.svg"
              alt=""
              width="34"
              height="34"
              class="featured-bubble-2 lazyload"
            />
            <img
              data-src="~/assets/icons/ic_bubble_3.svg"
              alt=""
              width="34"
              height="34"
              class="featured-bubble-3 lazyload"
            />
            <img
              data-src="~/assets/icons/ic_bubble_4.svg"
              alt=""
              width="34"
              height="34"
              class="featured-bubble-4 lazyload"
            />
          </div>
        </div>
      </section>
    </div>
    <section
      class="container pax20"
      :class="
        isHeroSearchLargeEnabled() && $config.FT_HERO_SEARCH_MOBILE
          ? 'dn'
          : $config.FT_HERO_SEARCH_MOBILE
          ? 'dn db-l'
          : isHeroSearchLargeEnabled()
          ? 'db dn-l'
          : 'db'
      "
    >
      <div class="flex flex-column items-center flex-row-l mv4">
        <div class="w-50-l mr2 flex justify-center">
          <div class="mv4 features-mw">
            <h2 class="f2 mb4 mt2">{{ $t('home.features.feature1Title') }}</h2>
            <p class="">
              {{ $t('home.features.feature1Description') }}
            </p>
          </div>
        </div>
        <div class="w-50-l ml2">
          <div class="pv4 flex justify-center features-screen features-mw">
            <img
              :data-srcset="require('~/assets/images/guide-find@2x.png').srcSet"
              data-src="~assets/images/guide-find.png"
              alt=""
              class="lazyload"
              width="274"
              height="477"
            />
          </div>
        </div>
      </div>
      <div
        class="flex flex-column items-center flex-row-l flex-row-reverse-l mb4"
      >
        <div class="w-50-l ml2 flex justify-center">
          <div class="mv4 features-mw">
            <h2 class="f2 mt2">{{ $t('home.features.feature2Title') }}</h2>
            <p class="">{{ $t('home.features.feature2Description') }}</p>
          </div>
        </div>
        <div class="w-50-l mr2 flex justify-end">
          <div class="pv4 flex justify-center features-screen features-mw flip">
            <img
              :data-srcset="
                require('~/assets/images/guide-manage@2x.png').srcSet
              "
              data-src="~assets/images/guide-manage.png"
              alt=""
              class="lazyload flip"
              width="274"
              height="477"
            />
          </div>
        </div>
      </div>
      <div class="flex flex-column items-center flex-row-l">
        <div class="w-50-l mr2 flex justify-center">
          <div class="mv4 features-mw">
            <h2 class="f2 mt2">{{ $t('home.features.feature3Title') }}</h2>
            <p class="">{{ $t('home.features.feature3Description') }}</p>
          </div>
        </div>
        <div class="w-50-l ml2">
          <div class="pv4 flex justify-center features-screen features-mw">
            <img
              :data-srcset="require('~/assets/images/guide-pay@2x.png').srcSet"
              data-src="~assets/images/guide-pay.png"
              alt=""
              class="lazyload"
              width="274"
              height="477"
            />
          </div>
        </div>
      </div>
    </section>
    <div class="bg-near-white">
      <div class="container pax20">
        <div class="pt4">
          <h2 class="f2">{{ $t('home.services.heading') }}</h2>
          <CityChooser />
          <div class="flex flex-wrap mt4 nl1 nr1 nl3-l nr3-l">
            <nuxt-link
              v-for="service in services.slice(0, 6)"
              :key="service.title"
              :to="
                localePath({
                  name: 'services-city-service',
                  params: {
                    city: getCitySlug(),
                    service: service.slug,
                  },
                })
              "
              class="nuxt-link db w-50 w-25-m w-third-l ph1 ph3-l no-underline"
            >
              <div class="bg-white overflow-hidden br3 card mb4">
                <div class="service-card">
                  <img :data-src="service.image" class="image-cover lazyload" />
                </div>
                <div class="service-card-text pax20 h-auto-l flex items-center">
                  <p class="fs-title fw7 ma0">
                    {{ service.localizedTitle[currentLang] }}
                  </p>
                  <!-- <p class="mv0">nuo 15 € / val.</p> -->
                </div>
              </div>
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>
    <div class="download">
      <DownloadNow />
    </div>
    <div class="pv2 pv5-l info-section">
      <div class="container phx20">
        <div
          class="mv3 flex flex-column items-center flex-row-l flex-row-reverse-l"
        >
          <div class="tl mw6-m w-50-l pl3">
            <h2 class="f2 mt0-l">{{ $t('home.information.heading') }}</h2>
            <p class="mb4 features-mw">
              {{ $t('home.information.subheading') }}
            </p>
            <div class="flex flex-column flex-row-l items-center mb4">
              <nuxt-link
                :to="localePath({ name: 'business' })"
                class="no-underline pointer mb2 mb0-l dib white fw7 br-pill bg-green button-large"
                >{{ $t('home.information.learnMore') }}</nuxt-link
              >
              <!--<a
                class="fw5 ml3 black link underline-border"
                href="https://help.discontract.com/lt/collections/1989243-naudojimo-taisykles-ir-saugumas"
                >{{ $t('home.information.legal') }}</a
              >-->
            </div>
          </div>
          <div class="mt3 mt0-l w-50-l tc">
            <img
              class="lazyload h-auto"
              :data-srcset="
                require('~/assets/images/business-central.png').srcSet
              "
              data-src="~assets/images/business-central.png"
              width="1433"
              height="1354"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="blog && news" class="bg-near-white pt3 pb2">
      <div class="container phx20">
        <div class="flex flex-column items-center-m flex-row-l tl">
          <div class="mb4 w-100 w-50-l pr5">
            <p class="f5">
              <span class="f3">&#8226;</span>
              {{ $t('home.information.offersHeading') }}
              <span class="ml2 bg-purple br-pill white ph3 f6 ttu">{{
                news.cities[0]
              }}</span>
            </p>
            <nuxt-link
              :to="
                localePath({ name: 'news-slug', params: { slug: news.slug } })
              "
              class="no-underline"
            >
              <h3 class="f3 fw7">
                {{ news.title }}
              </h3>
            </nuxt-link>
            <nuxt-link
              :to="
                localePath({ name: 'news-slug', params: { slug: news.slug } })
              "
              class="fw5 link underline"
              >{{ $t('home.information.learnMore') }}</nuxt-link
            >
          </div>
          <div class="mb4 w-100 w-50-l pr5">
            <p class="f5">
              <span class="f3">&#8226;</span>
              {{ $t('home.information.blogHeading') }}
            </p>
            <nuxt-link
              :to="
                localePath({ name: 'blog-slug', params: { slug: blog.slug } })
              "
              class="no-underline"
            >
              <h3 class="f3 fw7">
                {{ blog.title }}
              </h3>
            </nuxt-link>
            <nuxt-link
              :to="
                localePath({ name: 'blog-slug', params: { slug: blog.slug } })
              "
              class="fw5 link underline"
              >{{ $t('home.information.learnMore') }}</nuxt-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import slug from 'slug';
import GetAppForm from '~/components/GetAppForm';
import DownloadNow from '~/components/DownloadNow';
import CityChooser from '~/components/CityChooser';
import SearchLarge from '~/components/Search/SearchLarge';
import SearchMobile from '~/components/Search/SearchMobile';
import api from '~/services/api';
import reviews from '~/reviews';

export default {
  name: 'Home',
  scrollToTop: true,
  components: {
    SearchLarge,
    SearchMobile,
    GetAppForm,
    DownloadNow,
    CityChooser,
  },
  async asyncData(context) {
    const { app, $firebaseDb, $sentry } = context;
    try {
      const locale = app.i18n.locale;
      const language = locale.split('-')[0];
      let testimonial;
      const testimonials = reviews.filter((r) => {
        return r.language === language || (!r.language && language === 'lt');
      });
      if (testimonials.length) {
        testimonial =
          testimonials[Math.floor(Math.random() * testimonials.length)];
      }
      let blog = '';
      const blogSnap = await $firebaseDb
        .collection('blog')
        .where('language', '==', locale.split('-')[0])
        .where('countries', 'array-contains', locale.split('-')[1])
        .orderBy('date', 'desc')
        .limit(1)
        .get();
      blogSnap.forEach((post) => {
        const data = post.data();
        blog = {
          slug: post.id,
          title: data.title,
        };
      });
      let news = '';
      const newsSnap = await $firebaseDb
        .collection('news')
        .where('language', '==', locale.split('-')[0])
        .where('countries', 'array-contains', locale.split('-')[1])
        .orderBy('date', 'desc')
        // .limit(10)
        .get();
      newsSnap.forEach((post) => {
        const data = post.data();
        if (data.active && !news) {
          news = {
            slug: post.id,
            title: data.title,
            cities: data.cities,
          };
        }
      });

      const services = [];
      const response = await api.getServices(context);

      const servicesWithSkills = {};
      Object.values(response.skills)
        .sort((d2, d1) => (d1.sortPriority || 0) - (d2.sortPriority || 0))
        .forEach((data) => {
          if (!data.hidden) {
            if (locale.split('-')[1] === 'PL' && !data.areaPriceStats?.warsaw) {
              return;
            }
            servicesWithSkills[data.serviceId] = true;
          }
        });
      Object.values(response.services)
        .sort((d1, d2) => (d1.sortPriority || 0) - (d2.sortPriority || 0))
        .forEach((data) => {
          const trans = data.localizedTitle[language];
          if (!servicesWithSkills[data.id] || !trans) {
            return;
          }
          services.unshift({
            title: data.title,
            localizedTitle: data.localizedTitle,
            image: data.image,
            slug: slug(trans, { locale: language }),
          });
        });
      return { services, blog, news, testimonial, currentLang: language };
    } catch (error) {
      $sentry.captureException(error);
      throw error;
    }
  },
  data() {
    return {
      error: null,
      success: null,
      phone: null,
      services: [],
      getAppIsOpen: false,
      scrolled: 0,
      blog: '',
      news: '',
      testimonial: null,
      intersectionOptions: {
        root: null,
        rootMargin: '-10px',
        threshold: 1,
      },
    };
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    getCitySlug() {
      const locations = {
        LT: ['vilnius'],
        // LV: ['riga'],
        // EE: ['tallinn'],
        PL: ['warsaw'],
        // UA: ['kiev', 'lviv'],
      };
      const country = this.$i18n.locale.split('-')[1];
      const cityCode = locations[country][0];
      const cityTrans = this.$t(`cities.${country}.${cityCode}`);
      const citySlug = slug(cityTrans, {
        locale: this.$i18n.locale.split('-')[0],
      });
      return citySlug;
    },
    visibilityChanged(isHeroSearchVisible) {
      this.$root.$emit('toggle-header-search', isHeroSearchVisible);
    },
    getCountryCode() {
      return this.$i18n.locale.split('-')[1];
    },
    onScroll() {
      this.scrolled = window.pageYOffset;
    },
    isHeroSearchLargeEnabled() {
      return (
        this.$config.FT_HERO_SEARCH_LARGE &&
        (this.getCountryCode() === 'LT' ||
          (this.$config.FT_PL_ORDERS && this.getCountryCode() === 'PL'))
      );
    },
  },
  head() {
    return {
      title: this.$t('home.title'),
      meta: [
        // hid is used as unique identifier. Do not use `vmid` for it as it will not work
        {
          hid: 'description',
          name: 'description',
          content: this.$t('home.description'),
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.$t('home.description'),
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.$t('home.title'),
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: `https://discontract.com${this.$route.path.replace(
            /\/?$/,
            '/'
          )}`,
        },
      ],
    };
  },
};
</script>
