<template>
  <div
    class="featured-container-dn flex flex-column items-center flex-row-l tl relative"
  >
    <div class="container pax20 w-100">
      <div class="pr6-l ph0-m w-50-l">
        <h2 class="f2">
          {{ heading !== '' ? heading : $t('home.download.heading') }}
        </h2>
        <p class="mb4 pb2">{{ $t('home.download.subheading') }}</p>
        <div class="flex">
          <a
            :href="iosLink"
            target="_blank"
            rel="noopener noreferrer"
            class="mr2"
            @click.stop="
              $analytics.trackEvent(events.EXECUTE_GET_APP, {
                from: $route.path,
                medium: 'app_store_link',
              })
            "
          >
            <img
              class="lazyload"
              data-src="~assets/images/badge-app-store.svg"
              height="44"
            />
          </a>
          <a
            :href="androidLink"
            target="_blank"
            rel="noopener noreferrer"
            @click.stop="
              $analytics.trackEvent(events.EXECUTE_GET_APP, {
                from: $route.path,
                medium: 'google_play_link',
              })
            "
          >
            <img
              class="lazyload"
              data-src="~assets/images/badge-google-play.svg"
              width="150"
              height="44"
            />
          </a>
        </div>
      </div>
    </div>
    <div class="featured-image-container-dn absolute-l top-0 right-0 w-50-l">
      <img
        :data-srcset="require('~/assets/images/download-app@2x.jpg').srcSet"
        data-src="~assets/images/download-app@2x.jpg"
        class="lazyload featured-image-dn"
      />
    </div>
  </div>
</template>

<script>
import {
  IOS_APP_STORE,
  ANDROID_GOOGLE_PLAY,
  ANDROID_DIRECT_GOOGLE_PLAY,
} from '~/constants/links';
import events from '~/constants/events';

export default {
  props: {
    heading: {
      type: String,
      default: '',
    },
  },
  computed: {
    events() {
      return events;
    },
    iosLink() {
      return IOS_APP_STORE;
    },
    androidLink() {
      return ANDROID_GOOGLE_PLAY;
    },
    androidDirectLink() {
      return ANDROID_DIRECT_GOOGLE_PLAY;
    },
  },
};
</script>

<style scoped>
.featured-image-container-dn {
  height: 375px;
}

.featured-image-dn {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top left;
}

@media screen and (min-width: 60em) {
  .featured-container-dn,
  .featured-image-container-dn {
    height: 650px;
  }
}
</style>
